import React from "react";
import { graphql } from "gatsby"

import { Base } from "../components/layout";
import PostsContent from "../components/page-contents/PostsContent";
import SEO from "../components/seo";

type Props = {
  data: any
}

export const Head = () => <SEO title="Syrona Health | News" />

const NewsPage: React.FC<Props> = ({ data }) => <Base><PostsContent title="Latest News" nodes={data.allPrismicNewsItem.nodes} filterOnCategory={false} linkCreator={uid => `/news/${uid}`} /></Base>

export default NewsPage;

export const query = graphql`
  query AllPrismicNewsItem(
    $order: [SortOrderEnum]
    $fields: [PrismicNewsItemFieldsEnum]
  ) {
    allPrismicNewsItem(sort: { order: $order, fields: $fields }) {
      nodes {
        uid
        data {
          date
          title {
            text
          }
          cover {
            url
          }
          content {
            text
            richText
          }
          author
        }
      }
    }
  }
`
